.main_wrapper {
    position: relative;
    background: antiquewhite;
}
.header {
    display: flex;
    justify-content: space-between;
    background: tomato;
    color: #fff;
    padding: 17px 7px;
}
.top-heading {
    text-align: center;
}
img.img_wrapper {
    width: 100%;
}
.movie_card_wrap {
    display: flex;
    flex-wrap: wrap;
}

.card_body {
    display: flex;
    flex-direction: column;
    padding: 7px 1px;
}
.movie_card_container {
    background: gray;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 7px;
    margin: 10px;
    border-radius: 2px;
    flex: 0 0 17%;
}
img.img_header {
    width: 55px;
}
.header_right {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 1024px) {
    .movie_card_container {
        background: gray;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 7px;
        margin: 10px;
        border-radius: 2px;
        flex: 0 0 45%;
    }
  }

  /* media query for mobile view 700px*/

  @media screen and (max-width: 700px) {
    .movie_card_container {
        background: gray;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 7px;
        margin: 10px;
        border-radius: 2px;
        flex: 0 0 91%;
    }
  }